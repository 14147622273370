// @flow
import React, { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useParams } from 'react-router-dom';
import { useRedirectPuzzle } from './hooks/puzzleHooks';

import type { TDispatch } from '../../store/store';

import { answers } from '../../puzzles/gtl_puzzles';
import { IMAGE_EXTENSION, PUZZLE_TYPES } from '../../constants';
import { $NotNull, isAnswerCorrect, calculatePuzzleDate } from '../../globals';
import { setPreviousPuzzles, setStats } from '../../store/slices/statSlice';
import AutoComplete from '../AutoComplete';

import { calcStats, loadLocalStorageItem, saveLocalStorageItem, loadMustRedirect } from '../../localStateUtils';
import { calcPreviousPuzzleResults } from '../../prevPuzzleUtils';
import { populateGameList } from '../../puzzleUtils';
import { autocompleteSearchThunk, submitGuessThunk } from '../../store/slices/puzzleSlice';
import { debounce } from 'lodash';
import { useSettings } from '../../settings/useSettings';
import type { State } from '../../store/types';
import { useTranslation } from 'react-i18next';
import { getInitialGameState, getInitialGuesses, getInitialGuessList } from './PuzzleUtils';
import Footer from '../Footer';
import { setPuzzleType } from '../../store/slices/uxSlice';
import { Helmet } from 'react-helmet';
import ShareResult from '../ShareResult';
import ShowGuesses from '../ShowGuesses';
import KofiButton from '../Kofi';
import NavigateButton from '../NavigateButton';
import FinalAnswer from '../FinalAnswer';
import FinalMessage from '../FinalMessage';
import type { GameSettings } from '../../settings/game_settings';

const getInitialFranchiseMatchList = (originalSaveState: ?string, puzzleId: string, settings: GameSettings) => {
    return originalSaveState
        ? [1, 2, 3, 4, 5, 6].map(
              (val) => loadLocalStorageItem(puzzleId, `${settings.storage_keys.guess}${val}_f`) === 'true',
          )
        : Array(6).fill(false);
};

const getInitialBlurIdxs = (puzzleId: string, originalSaveState: ?string, settings: GameSettings): Array<number> => {
    let blurIdxs = [0, 1, 2, 3, 4, 5, 6, 7, 8];
    if (!originalSaveState) {
        return blurIdxs;
    }
    if (originalSaveState === 'win' || originalSaveState === 'lose') {
        return [];
    }
    const numGuesses = getInitialGuesses(puzzleId, originalSaveState, settings);
    for (let i = 0; i < numGuesses; i++) {
        blurIdxs = blurIdxs.filter(
            // $FlowIgnore
            (val) => val !== settings.answers[puzzleId].uncover_order[i],
        );
    }
    return blurIdxs;
};

const GTLPuzzle = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();
    const puzzleType = PUZZLE_TYPES.GTL;

    let { puzzleId } = useParams();
    puzzleId = puzzleId ?? settings.num_days_from_start_date().toString();

    const { t } = useTranslation();

    const savedState = loadLocalStorageItem(puzzleId, settings.storage_keys.game_state);
    if (savedState == null && settings.puzzle_type === puzzleType) {
        // Populate new state
        saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'playing');
    }

    const myImage = useRef<?HTMLImageElement>(null);
    const myCanvas = useRef<?HTMLCanvasElement>(null);

    const [imgLoaded, setImgLoaded] = useState(false);
    const [guessInputValue, setGuessInputValue] = useState('');

    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [filteredSuggestionsMetadata, setFilteredSuggestionsMetadata] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [previousGuesses, setPreviousGuesses] = useState({});
    const [ACloading, setACloading] = useState(false);

    const [gameState, setGameState] = useState(() => getInitialGameState(savedState));
    const [guessNum, setGuessNum] = useState(() => getInitialGuesses(savedState, puzzleId, settings));
    const [blurredIdxs, setBlurredIdxs] = useState(() => getInitialBlurIdxs(puzzleId, savedState, settings));
    const [guessList, setGuessList] = useState(() => getInitialGuessList(savedState, puzzleId, settings));
    const [franchiseMatch, setFranchiseMatch] = useState(() =>
        getInitialFranchiseMatchList(savedState, puzzleId, settings),
    );

    const answer = answers[puzzleId].answers;
    const franchise = $NotNull(answers[puzzleId].franchise);
    const pixelation_level = Number(answers[puzzleId].pixelation_level);

    const stateGuesses = useSelector((state: State) => state.stats[puzzleType].guesses);
    useEffect(() => {
        const savedState = loadLocalStorageItem(puzzleId, settings.storage_keys.game_state);
        setGameState(getInitialGameState(savedState));
        setGuessNum(getInitialGuesses(savedState, puzzleId, settings));
        setGuessList(getInitialGuessList(savedState, puzzleId, settings));
        setFranchiseMatch(getInitialFranchiseMatchList(savedState, puzzleId, settings));
        setBlurredIdxs(getInitialBlurIdxs(puzzleId, savedState, settings));
    }, [stateGuesses, puzzleId, settings]);

    useRedirectPuzzle(guessNum);

    useEffect(() => {
        dispatch(setPuzzleType(puzzleType));
    });

    const pixelateSegments = (canvas, image, segments, pixelation_level = 30) => {
        console.log('pixelating segments');
        const ctx = canvas.getContext('2d');
        const pixelSize = pixelation_level; // Size of the pixel blocks

        // Create a smaller offscreen canvas for pixelation
        const offscreenCanvas = document.createElement('canvas');
        // Scale down factor (adjust as needed). if the image height is less than 1000px, scale it up to 0.4 otherwise its 0.2
        // on puzzle #50 we started using brandeps which is a lower quality image so we need to scale it up more
        const scaleFactor = image.height < 1000 ? 0.4 : 0.2;
        offscreenCanvas.width = canvas.width * scaleFactor;
        offscreenCanvas.height = canvas.height * scaleFactor;
        const offscreenCtx = offscreenCanvas.getContext('2d');

        // Draw the image on the offscreen canvas
        offscreenCtx.drawImage(image, 0, 0, offscreenCanvas.width, offscreenCanvas.height);

        for (let i = 0; i < 9; i++) {
            let x = i % 3;
            let y = Math.floor(i / 3);
            const startX = (x * offscreenCanvas.width) / 3;
            const startY = (y * offscreenCanvas.height) / 3;
            const width = offscreenCanvas.width / 3;
            const height = offscreenCanvas.height / 3;

            if (segments.includes(i)) {
                for (let px = 0; px < width; px += pixelSize) {
                    for (let py = 0; py < height; py += pixelSize) {
                        const imageData = offscreenCtx.getImageData(startX + px, startY + py, pixelSize, pixelSize);
                        const avgColor = getAverageColor(imageData);
                        // console.log(avgColor.r, avgColor.g, avgColor.b);
                        // if (avgColor.r < 3 || avgColor.g < 3 || avgColor.b < 3){
                        //    avgColor.r = 15
                        //    avgColor.g = 23
                        //    avgColor.b = 43
                        // }
                        offscreenCtx.fillStyle = `rgb(${avgColor.r}, ${avgColor.g}, ${avgColor.b})`;
                        offscreenCtx.fillRect(startX + px, startY + py, pixelSize, pixelSize);
                    }
                }
            }
        }

        // Draw the pixelated image back to the main canvas
        ctx.drawImage(offscreenCanvas, 0, 0, canvas.width, canvas.height);
    };

    const getAverageColor = (imageData) => {
        const data = imageData.data;
        let r = 0,
            g = 0,
            b = 0;
        for (let i = 0; i < data.length; i += 4) {
            r += data[i];
            g += data[i + 1];
            b += data[i + 2];
        }
        const pixelCount = data.length / 4;
        return {
            r: Math.floor(r / pixelCount),
            g: Math.floor(g / pixelCount),
            b: Math.floor(b / pixelCount),
        };
    };

    const blurSegments = (canvas: HTMLCanvasElement, image: HTMLImageElement, segments: Array<number>) => {
        console.log('blurring segments');
        // Create a temporary canvas to apply blur to.
        let tmpCanvas = document.createElement('canvas');
        tmpCanvas.height = canvas.height;
        tmpCanvas.width = canvas.width;

        let ctx1 = tmpCanvas.getContext('2d');
        ctx1.clearRect(0, 0, tmpCanvas.width, tmpCanvas.height);
        ctx1.filter = 'blur(90px)';
        ctx1.drawImage(image, 0, 0);

        let ctx2 = canvas.getContext('2d');
        for (let i = 0; i < 9; i++) {
            let x = i % 3;
            let y = Math.floor(i / 3);
            // Safari doesn't like it if you overflow a dimension, so let's cap it
            const calcSize = (offset: number, dimension: number) =>
                (offset * dimension) / 3 + dimension / 3 + 1 > dimension ? dimension / 3 : dimension / 3 + 1;
            if (segments.includes(i)) {
                ctx2.drawImage(
                    tmpCanvas,
                    (x * tmpCanvas.width) / 3,
                    (y * tmpCanvas.height) / 3,
                    calcSize(x, tmpCanvas.width),
                    calcSize(y, tmpCanvas.height),
                    (x * image.width) / 3,
                    (y * image.height) / 3,
                    calcSize(x, image.width),
                    calcSize(y, image.height),
                );
            } else {
                ctx2.drawImage(
                    image,
                    (x * image.width) / 3,
                    (y * image.height) / 3,

                    calcSize(x, image.width),
                    calcSize(y, image.height),
                    (x * image.width) / 3,
                    (y * image.height) / 3,

                    calcSize(x, image.width),
                    calcSize(y, image.height),
                );
            }
        }
    };

    const onImageLoad = () => {
        let image = $NotNull(myImage.current);
        let canvas = $NotNull(myCanvas.current);

        canvas.width = image.width;
        canvas.height = image.height;

        setImgLoaded(true);
    };

    useEffect(() => {
        if (imgLoaded) {
            let image = $NotNull(myImage.current);
            let canvas = $NotNull(myCanvas.current);

            // blurSegments(canvas, image, blurredIdxs);
            pixelateSegments(canvas, image, blurredIdxs, pixelation_level);
        }
    }, [blurredIdxs, imgLoaded, pixelateSegments]);

    const submitGuess = (e: Event | null, isSkipGuess: boolean) => {
        const mustRedirect = loadMustRedirect();
        if (mustRedirect) {
            // Game cannot proceed even if users remove the redirect modal manually, uBlock etc
            return;
        }

        let currentInputValue;
        if (isSkipGuess !== undefined) {
            currentInputValue = 'Skipped!';
        } else {
            currentInputValue = guessInputValue;
        }

        if (guessInputValue === '' && isSkipGuess !== true) return;

        const guessFranchise = filteredSuggestionsMetadata.filter(
            (suggestion) => suggestion.title.toLowerCase() === currentInputValue.toLowerCase(),
        )[0]?.franchise;
        const guessFranchiseMatch = guessFranchise === franchise;

        let newGuessNum = 0;
        for (let i = 0; i < guessList.length; i++) {
            if (guessList[i] === '') {
                let newArr = [...guessList];
                newArr[i] = currentInputValue;
                setGuessList(newArr);
                let newFranchiseMatch = [...franchiseMatch];
                newFranchiseMatch[i] = guessFranchiseMatch;
                setFranchiseMatch(newFranchiseMatch);
                saveLocalStorageItem(
                    puzzleId,
                    `${settings.storage_keys.guess}${i + 1}_f`,
                    guessFranchiseMatch.toString(),
                );
                saveLocalStorageItem(puzzleId, `${settings.storage_keys.guess}${i + 1}`, currentInputValue);
                newGuessNum = i + 1;

                setGuessNum(newGuessNum);
                if (isAnswerCorrect(currentInputValue, answer)) {
                    setGameState('win');
                    saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'win');
                    setBlurredIdxs([]);
                    // delete the canvas
                    let canvas = $NotNull(myCanvas.current);
                    let ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                } else {
                    // If we're on guess 1-5, then shake and go to the next image
                    if (i < guessList.length - 1) {
                        let newBlurredIdx = [...blurredIdxs];
                        const newGuessNumConst = newGuessNum;
                        newBlurredIdx = newBlurredIdx.filter(
                            // $FlowIgnore
                            (val) => val !== answers[puzzleId].uncover_order[newGuessNumConst - 1],
                        );
                        setBlurredIdxs(newBlurredIdx);
                        //todo add this to guess the cover 8/25/23
                        let canvas = $NotNull(myCanvas.current);
                        let ctx = canvas.getContext('2d');
                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                    } else {
                        // If we're on guess 6, then they lost
                        setGameState('lose');
                        saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'lose');
                        setBlurredIdxs([]);
                        let canvas = $NotNull(myCanvas.current);
                        let ctx = canvas.getContext('2d');
                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                    }
                }
                break;
            }
        }
        const initialStats = calcStats(settings);
        dispatch(
            setStats({
                won: initialStats.totalWon,
                played: initialStats.totalPlayed,
                currentStreak: initialStats.currentStreak,
                maxStreak: initialStats.maxStreak,
                puzzleType: puzzleType,
            }),
        );
        dispatch(
            setPreviousPuzzles({
                previousPuzzles: calcPreviousPuzzleResults(settings).previousPuzzles,
                puzzleType: puzzleType,
            }),
        );

        setGuessInputValue('');

        dispatch(
            submitGuessThunk({
                puzzleId: parseInt(puzzleId),
                guessNum: guessNum + 1,
                guess: currentInputValue,
                puzzleType: puzzleType,
            }),
        );
    };

    const skipGuess = () => {
        submitGuess(null, true);
    };

    const debouncedSave = useMemo(
        (userInput, previousGuesses) =>
            debounce((userInput, previousGuesses) => {
                const delayTimer = setTimeout(() => {
                    const unLinked = populateGameList(answers).filter(
                        (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
                    );
                    setFilteredSuggestions(unLinked);
                    setActiveSuggestionIndex(0);
                    setShowSuggestions(true);
                    setACloading(false);
                }, 3000);

                dispatch(
                    autocompleteSearchThunk({
                        userInput: userInput,
                        puzzleId: puzzleId,
                        itemType: settings.guess_item.toLowerCase(),
                        puzzleType: puzzleType,
                    }),
                )
                    .unwrap()
                    .then((response) => {
                        clearTimeout(delayTimer); // Cancel the timeout
                        const unLinked = response.map((result) => result.title);
                        setFilteredSuggestions(unLinked);
                        setFilteredSuggestionsMetadata(response);
                        setActiveSuggestionIndex(0);
                        setShowSuggestions(true);

                        // store unlinked in previous guesses
                        setPreviousGuesses({ ...previousGuesses, [userInput]: unLinked });
                        setACloading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching autocomplete results:', error);
                        setACloading(false);
                    });
            }, 290),
        [dispatch, puzzleId, settings, puzzleType, answers],
    );

    const onChange = (e: Event) => {
        // $FlowIgnore target has value
        const userInput = e.target.value;
        setGuessInputValue(userInput);
        if (userInput.length <= 2) {
            setFilteredSuggestions([]);
            setFilteredSuggestionsMetadata([]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
            return;
        }
        // check if userInput is in previous guesses
        if (previousGuesses[userInput]) {
            debouncedSave.cancel();
            console.log('already searched this');
            setFilteredSuggestions(previousGuesses[userInput]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
            setACloading(false);
            return;
        }
        setACloading(true);
        debouncedSave(userInput, previousGuesses);
    };

    const onKeyDown = (key: KeyboardEvent) => {
        if (key.keyCode === 13 || key.keyCode === 9) {
            if (filteredSuggestions.length > 0) {
                setGuessInputValue(filteredSuggestions[activeSuggestionIndex]);
            }
            setShowSuggestions(false);
        }
    };

    const onClick = (e: Event) => {
        setFilteredSuggestions([]);
        // $FlowIgnore target has innertext
        setGuessInputValue(e.target.innerText);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    };

    const currentPuzzleUrl = settings.game_url(puzzleId);
    const description = 'Try to ' + settings.website_title + '? Daily Puzzle #' + puzzleId;
    const puzzleTitle = settings.website_title + ' Daily Puzzle #' + puzzleId;
    const puzzleDate = calculatePuzzleDate(puzzleId, settings);
    const showPuzzleDate = puzzleDate !== new Date().toDateString();

    return (
        <div className="current-game">
            <Helmet>
                <title>
                    {settings.website_title} #{puzzleId}
                </title>

                <link rel="canonical" href={currentPuzzleUrl} />
                <meta property="og:url" content={currentPuzzleUrl} />
                <meta property="twitter:url" content={currentPuzzleUrl} />

                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta property="twitter:description" content={description} />

                <meta property="og:title" content={puzzleTitle} />
                <meta property="twitter:title" content={puzzleTitle} />

                <meta property="og:type" content="website" />

                <meta property="og:site_name" content={settings.website_url} />

                {/* defaulting it to puzzle 1 so you dont actually send the logo when sharing to friends */}
                <meta property="twitter:image" content={`/${settings.puzzle_type}_games/1/1.${IMAGE_EXTENSION}`} />
                <meta property="og:image" content={`/${settings.puzzle_type}_games/1/1.${IMAGE_EXTENSION}`} />

                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div
                className={'current-game-number'}
                style={{
                    textAlign: 'center',
                    color: '#eee',
                    opacity: '50%',
                    paddingBottom: '10px',
                    fontStyle: 'oblique',
                }}
            >
                {settings.title} #{puzzleId}
                <br />
                {showPuzzleDate && puzzleDate}
            </div>
            <div className="Screenshots" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="image-area" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        ref={myImage}
                        crossOrigin="anonymous"
                        src={`/${settings.puzzle_type}_games/${puzzleId}/1.webp`}
                        alt="guess the cover"
                        // height="400px"
                        onLoad={() => onImageLoad()}
                        style={{ display: 'none' }}
                        className={'gtl-image'}
                    />
                    <canvas className={'gtl-image'} ref={myCanvas} />
                </div>
            </div>
            {gameState !== 'playing' && (
                <div className="result">
                    <FinalMessage gameState={gameState} num_guesses={guessNum} />
                    <FinalAnswer answer={answer} />
                    <ShareResult
                        guesses={guessNum}
                        gameState={gameState}
                        puzzleId={puzzleId}
                        franchiseMatch={franchiseMatch}
                        maxGuesses={settings.max_guesses}
                    />
                    <ShowGuesses guessList={guessList} answer={answer} puzzleId={puzzleId} />
                    <KofiButton preset="kufi-center" />
                    <NavigateButton
                        text={t('Play Previous Days')}
                        path={settings.paths.previous_games}
                        params={createSearchParams({ puzzleType: puzzleType }).toString()}
                        hash={puzzleId}
                    />
                    <NavigateButton
                        text={'See Game Stats'}
                        path={settings.paths.stats.replace(':puzzleId', puzzleId)}
                    />
                </div>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guessNum < settings.max_guesses - 1 && (
                <p className="guesses-remaining">
                    {settings.max_guesses - guessNum} {settings.max_guesses - guessNum === 1 ? 'guess' : 'guesses'}{' '}
                    {t('remaining')}!
                </p>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guessNum >= settings.max_guesses - 1 && (
                <p className="guesses-remaining">{t('Last guess')}!</p>
            )}
            {gameState === 'playing' && (
                <div className="PlayArea">
                    <div className="input-area">
                        <AutoComplete
                            onChangeFn={onChange}
                            onKeyDownFn={onKeyDown}
                            onClickFn={onClick}
                            activeSuggestionIndex={activeSuggestionIndex}
                            filteredSuggestionsList={filteredSuggestions}
                            inputValue={guessInputValue}
                            shouldShowSuggestions={showSuggestions}
                            loading={ACloading}
                        />
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <button
                            type="button"
                            className="mainButton submitButton"
                            onClick={submitGuess}
                            style={{ marginRight: '5px' }}
                        >
                            {t('Submit')}
                        </button>
                        <button
                            type="button"
                            className="mainButton iconButton skipButton"
                            onClick={skipGuess}
                            style={{ marginTop: '0px' }}
                        >
                            Skip <span aria-hidden="true" className="icon-forward3"></span>
                        </button>
                    </div>
                    {guessList.map((guess, idx) => {
                        let emoji = null;
                        if (isAnswerCorrect(guess, answer)) {
                            emoji = '✅';
                        } else if (franchiseMatch[idx]) {
                            emoji = '🟡';
                        } else if (!isAnswerCorrect(guess, answer)) {
                            emoji = '❌';
                        }
                        return guess === '' ? null : (
                            <div key={'gl-' + idx} className="guess-result">
                                {emoji}
                                {guess}
                                <span className="guess-result-franchise">
                                    {franchiseMatch[idx] && ` (Franchise: ${franchise})`}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
            <Footer puzzleId={puzzleId} currentPage={'puzzle'} puzzleType={puzzleType} />
        </div>
    );
};

export default GTLPuzzle;
