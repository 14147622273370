/* eslint-disable no-useless-escape */
// @flow

export type TGTMCAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    source: string,
    uncover_order: Array<string>,
};

type TGTMCAnswers = {
    [string]: TGTMCAnswer
};

export const answers: TGTMCAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Inside Out 2"],
        affiliate_links: [],
        franchise: 'Inside Out',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/vpnVM9B6NMmQpWeZvzLvDESb2QY.jpg',
        uncover_order: [0, 2, 3, 5],
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["A Matter of Loaf and Death", "Wallace & Gromit: A Matter of Loaf and Death"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/lj7BjaweXQsRkwye9fI0iXRJ9Qi.jpg',
        uncover_order: [6, 5, 4, 1],
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["The Shawshank Redemption"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/9cqNxx0GxF0bflZmeSMuL5tnGzr.jpg',
        uncover_order: [2, 4, 1, 7],
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Trinity Is Still My Name"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/oRd5Zb2MJEvBBxvyh3gVe6d4cAl.jpg',
        uncover_order: [5, 6, 4, 2],
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Interstellar"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/gEU2QniE6E77NI6lCU6MxlNBvIx.jpg',
        uncover_order: [8, 3, 4, 2],
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["The Promised Land"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/npQgoOWn4fnvOIIyTj4rIV80FXC.jpg',
        uncover_order: [2, 3, 1, 7],
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["The Man from Earth"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/V086R82gNgWrotaXZFO4JhdgB1.jpg',
        uncover_order: [8, 3, 4, 1],
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["The Little Mermaid"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/plcZXvI310FkbwIptvd6rqk63LP.jpg',
        uncover_order: [8, 1, 0, 2],
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Braveheart"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/or1gBugydmjToAEq7OZY0owwFk.jpg',
        uncover_order: [2, 3, 1, 8],
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Logan"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/fnbjcRDYn6YviCcePDnGdyAkYsB.jpg',
        uncover_order: [2, 3, 1, 7],
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["The Garfield Movie"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/xYduFGuch9OwbCOEUiamml18ZoB.jpg',
        uncover_order: [7, 5, 3, 1],
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["To Kill a Mockingbird"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/gZycFUMLx2110dzK3nBNai7gfpM.jpg',
        uncover_order: [0, 3, 2, 5],
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Lawrence of Arabia"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/AiAm0EtDvyGqNpVoieRw4u65vD1.jpg',
        uncover_order: [6, 5, 4, 2],
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["The Great Escape"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/gBH4H8UMFxl139HaLz6lRuvsel8.jpg',
        uncover_order: [3, 2, 4, 7],
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Saving Bikini Bottom: The Sandy Cheeks Movie"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/30YnfZdMNIV7noWLdvmcJS0cbnQ.jpg',
        uncover_order: [0, 2, 1, 5],
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Coco"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/gGEsBPAijhVUFoiNpgZXqRVWJt2.jpg',
        uncover_order: [6, 5, 4, 2],
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["Witness for the Prosecution"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/iUIo7IVYOf4yHwGmVHyGJcb4QOe.jpg',
        uncover_order: [8, 0, 4, 1],
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["Your Name"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/q719jXXEzOoYaps6babgKnONONX.jpg',
        uncover_order: [6, 5, 3, 2],
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["Rear Window"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/ILVF0eJxHMddjxeQhswFtpMtqx.jpg',
        uncover_order: [3, 5, 7, 2],
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["The Great Dictator"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/1QpO9wo7JWecZ4NiBuu625FiY1j.jpg',
        uncover_order: [2, 3, 1, 7],
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["The Handmaiden"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/dLlH4aNHdnmf62umnInL8xPlPzw.jpg',
        uncover_order: [8, 6, 3, 2],
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["GoodFellas"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/aKuFiU82s5ISJpGZp7YkIr3kCUd.jpg',
        uncover_order: [6, 4, 1, 7],
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of 1900"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/4EKnIjT1IGGFyplaW11lTvXrzI0.jpg',
        uncover_order: [0, 2, 4, 7],
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["Ikiru"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/dgNTS4EQDDVfkzJI5msKuHu2Ei3.jpg',
        uncover_order: [8, 3, 4, 1],
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["Pulp Fiction"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/d5iIlFn5s0ImszYzBPb8JPIfbXD.jpg',
        uncover_order: [6, 5, 4, 2],
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["The Lord of the Rings: The Return of the King"],
        affiliate_links: [],
        franchise: 'The Lord of the Rings',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/rCzpDGLbOoPwLjy3OAm5NUPOTrC.jpg',
        uncover_order: [3, 5, 6, 8],
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["Seven Samurai"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/8OKmBV5BUFzmozIC3pPWKHy17kx.jpg',
        uncover_order: [7, 0, 4, 3],
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["Cinema Paradiso"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/gCI2AeMV4IHSewhJkzsur5MEp6R.jpg',
        uncover_order: [7, 2, 4, 1],
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["The Empire Strikes Back", "Star Wars: Episode V"],
        affiliate_links: [],
        franchise: 'Star Wars',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/nNAeTmF4CtdSgMDplXTDPOpYzsX.jpg',
        uncover_order: [0, 5, 4, 7],
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["Parasite"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/7IiTTgloJzvGI1TAYymCfbfl3vT.jpg',
        uncover_order: [2, 0, 3, 6],
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["The Shining"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/xazWoLealQwEgqZ89MLZklLZD3k.jpg',
        uncover_order: [6, 5, 3, 0],
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["The Good, the Bad and the Ugly"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/bX2xnavhMYjWDoZp1VM6VnU1xwe.jpg',
        uncover_order: [2, 3, 0, 5],
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Green Book"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/7BsvSuDQuoqhWmU2fL7W2GOcZHU.jpg',
        uncover_order: [8, 3, 4, 1],
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["L\u00e9on: The Professional"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/yI6X2cCM5YPJtxMhUd3dPGqDAhw.jpg',
        uncover_order: [0, 3, 7, 8],
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Hei"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/aLv87NgRJUPkQ6sVLP72IisDdt4.jpg',
        uncover_order: [6, 5, 3, 1],
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["Labyrinth"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/hbSdA1DmNA9IlfVoqJkIWYF2oYm.jpg',
        uncover_order: [3, 2, 4, 8],
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["Guardians of the Galaxy Vol. 2"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/y4MBh0EjBlMuOzv9axM4qJlmhzz.jpg',
        uncover_order: [0, 2, 1, 5],
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["Forrest Gump"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/arw2vcBveWOVZr6pxd9XTd1TdQa.jpg',
        uncover_order: [8, 3, 4, 2],
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Watchmen"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/zcCGhnlO4qi8ZqunUHAnYkwUtRX.jpg',
        uncover_order: [5, 0, 3, 1],
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["The Departed"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/nT97ifVT2J1yMQmeq20Qblg61T.jpg',
        uncover_order: [0, 1, 8, 6],
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["John Wick: Chapter 2"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/hXWBc0ioZP3cN4zCu6SN3YHXZVO.jpg',
        uncover_order: [3, 5, 8, 6],
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["Ant-Man and the Wasp: Quantumania"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/qnqGbB22YJ7dSs4o6M7exTpNxPz.jpg',
        uncover_order: [0, 2, 1, 5],
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["The Woman King"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/438QXt1E3WJWb3PqNniK0tAE5c1.jpg',
        uncover_order: [3, 0, 2, 4],
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["The Super Mario Bros. Movie"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/qNBAXBIQlnOThrVvA6mA2B5ggV6.jpg',
        uncover_order: [6, 3, 8, 0],
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["Police Academy"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/m5a1U549gokC1kxsqgQoRb6XpFg.jpg',
        uncover_order: [4, 7, 3, 1],
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["Dungeons & Dragons: Honor Among Thieves"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/v7UF7ypAqjsFZFdjksjQ7IUpXdn.jpg',
        uncover_order: [0, 2, 4, 8],
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["Cocaine Bear"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/gOnmaxHo0412UVr1QM5Nekv1xPi.jpg',
        uncover_order: [2, 3, 6, 8],
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["Aladdin"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/eLFfl7vS8dkeG1hKp5mwbm37V83.jpg',
        uncover_order: [8, 6, 7, 5],
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["Ghostbusters"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/7E8nLijS9AwwUEPu2oFYOVKhdFA.jpg',
        uncover_order: [0, 5, 3, 2],
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["Total Recall"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/wVbeL6fkbTKSmNfalj4VoAUUqJv.jpg',
        uncover_order: [8, 0, 1, 2],
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["The Last Kingdom: Seven Kings Must Die"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/qcNDxDzd5OW9wE3c8nWxCBQoBrM.jpg',
        uncover_order: [4, 1, 8, 6],
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["Edward Scissorhands"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/e0FqKFvGPdQNWG8tF9cZBtev9Em.jpg',
        uncover_order: [8, 0, 6, 5],
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["Avatar: The Way of Water"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/t6HIqrRAclMCA60NsSmeqe9RmNV.jpg',
        uncover_order: [2, 0, 1, 5],
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["Scream"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/3O3klyyYpAZBBE4n7IngzTomRDp.jpg',
        uncover_order: [2, 5, 3, 0],
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["Guardians of the Galaxy Volume 3"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/r2J02Z2OpNTctfOSN1Ydgii51I3.jpg',
        uncover_order: [2, 0, 1, 3],
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["Ghost"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/w9RaPHov8oM5cnzeE27isnFMsvS.jpg',
        uncover_order: [6, 8, 3, 7],
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["Scream VI", "Scream 6"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/wDWwtvkRRlgTiUr6TyLSMX8FCuZ.jpg',
        uncover_order: [2, 8, 0, 5],
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["Idiocracy"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/rKsiNxKjhWEwndOgWPs273oy9EZ.jpg',
        uncover_order: [4, 3, 5, 7],
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["Creed III"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/cvsXj3I9Q2iyyIo95AecSd1tad7.jpg',
        uncover_order: [3, 4, 0, 2],
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["Rocky III"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/u9cTEjzKOPB6xaTz4LBql1XE0HZ.jpg',
        uncover_order: [4, 5, 3, 6],
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["Rogue One: A Star Wars Story"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/i0yw1mFbB7sNGHCs7EXZPzFkdA1.jpg',
        uncover_order: [3, 2, 8, 1],
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["Her"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/lEIaL12hSkqqe83kgADkbUqEnvk.jpg',
        uncover_order: [0, 1, 2, 4],
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["The Dark Knight"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/qJ2tW6WMUDux911r6m7haRef0WH.jpg',
        uncover_order: [5, 3, 2, 4],
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["The Grey"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/a0Vt3wx8ciKmPt4iXPx9sH4HBVU.jpg',
        uncover_order: [3, 0, 5, 4],
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["John Wick: Chapter 4"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/vZloFAK7NmvMGKE7VkF5UHaz0I.jpg',
        uncover_order: [0, 7, 3, 2],
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Four"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/8HLQLILZLhDQWO6JDpvY6XJLH75.jpg',
        uncover_order: [2, 0, 8, 5],
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["The Matrix"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/p96dm7sCMn4VYAStA6siNz30G1r.jpg',
        uncover_order: [4, 3, 5, 0],
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["The Fox and the Hound"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/aC3k6XBaYnulGSkK8263ABjU3Md.jpg',
        uncover_order: [2, 3, 5, 8],
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["Puss in Boots: The Last Wish"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/kuf6dutpsT0vSVehic3EZIqkOBt.jpg',
        uncover_order: [1, 3, 2, 5],
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["The Thing"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/tzGY49kseSE9QAKk47uuDGwnSCu.jpg',
        uncover_order: [0, 2, 1, 5],
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["Mad Max Beyond Thunderdome", "Mad Max 3"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/jJlxcEVVUHnrUeEkQ0077VeHQpb.jpg',
        uncover_order: [2, 3, 4, 0],
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["Se7en", "Seven"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/191nKfP0ehp3uIvWqgPbFmI4lv9.jpg',
        uncover_order: [1, 4, 2, 5],
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["Avengers: Infinity War"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/7WsyChQLEftFiDOVTGkv3hFpyyt.jpg',
        uncover_order: [2, 0, 1, 4],
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["Top Gun: Maverick"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/62HCnUTziyWcpDaBO2i1DX17ljH.jpg',
        uncover_order: [2, 6, 1, 8],
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["Jumper"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/3pPZ9JhNz3VMmASVir5SMHvTDUU.jpg',
        uncover_order: [7, 8, 0, 2],
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Beasts: The Crimes of Grindelwald"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/fMMrl8fD9gRCFJvsx0SuFwkEOop.jpg',
        uncover_order: [7, 8, 5, 4],
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["The Greatest Showman"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/b9CeobiihCx1uG1tpw8hXmpi7nm.jpg',
        uncover_order: [3, 5, 2, 0],
    },
    '78': {
        content: '',
        submitted_by: '',
        answers: ["You Don't Mess with the Zohan"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/gBhLQmpCPoKFMCGsulMbIFzrBID.jpg',
        uncover_order: [6, 7, 3, 5],
    },
    '79': {
        content: '',
        submitted_by: '',
        answers: ["Shrek"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/iB64vpL3dIObOtMZgX3RqdVdQDc.jpg',
        uncover_order: [6, 8, 4, 3],
    },
    '80': {
        content: '',
        submitted_by: '',
        answers: ["Sherlock Holmes: A Game of Shadows"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/y1MYZkwhZK6L0Jy4YMuPktzDOfn.jpg',
        uncover_order: [2, 1, 0, 8],
    },
    '81': {
        content: '',
        submitted_by: '',
        answers: ["Warcraft"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/nZIIOs06YigBnvmlJ2hxZeA8eTO.jpg',
        uncover_order: [5, 2, 1, 0],
    },
    '82': {
        content: '',
        submitted_by: '',
        answers: ["Angels & Demons"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/tFZQAuulEOtFTp0gHbVdEXwGrYe.jpg',
        uncover_order: [2, 0, 6, 8],
    },
    '83': {
        content: '',
        submitted_by: '',
        answers: ["The Chronicles of Narnia: The Lion, the Witch and the Wardrobe"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/iREd0rNCjYdf5Ar0vfaW32yrkm.jpg',
        uncover_order: [7, 6, 8, 3],
    },
    '84': {
        content: '',
        submitted_by: '',
        answers: ["Doctor Strange in the Multiverse of Madness"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/9Gtg2DzBhmYamXBS1hKAhiwbBKS.jpg',
        uncover_order: [5, 2, 0, 1],
    },
    '85': {
        content: '',
        submitted_by: '',
        answers: ["Nacho Libre"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/kh7B91bMl2lZ0mH9WhPfaNUIEQH.jpg',
        uncover_order: [6, 8, 0, 2],
    },
    '86': {
        content: '',
        submitted_by: '',
        answers: ["Teenage Mutant Ninja Turtles"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/shfAU6xIIEAEtsloIT3n9Fscz2E.jpg',
        uncover_order: [3, 4, 2, 7],
    },
    '87': {
        content: '',
        submitted_by: '',
        answers: ["Blade"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/oWT70TvbsmQaqyphCZpsnQR7R32.jpg',
        uncover_order: [5, 3, 0, 4],
    },
    '88': {
        content: '',
        submitted_by: '',
        answers: ["Traffic"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/jbccmnqE4oAPI67bApgt2JiRPz8.jpg',
        uncover_order: [2, 1, 5, 3],
    },
    '89': {
        content: '',
        submitted_by: '',
        answers: ["The Italian Job"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/8NOw3HPHkcBndJW2K6ofbcSb7WD.jpg',
        uncover_order: [2, 6, 1, 0],
    },
    '90': {
        content: '',
        submitted_by: '',
        answers: ["Hacksaw Ridge"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/wuz8TjCIWR2EVVMuEfBnQ1vuGS3.jpg',
        uncover_order: [2, 6, 8, 7],
    },
    '91': {
        content: '',
        submitted_by: '',
        answers: ["The Batman"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/74xTEgt7R36Fpooo50r9T25onhq.jpg',
        uncover_order: [3, 2, 0, 6],
    },
    '92': {
        content: '',
        submitted_by: '',
        answers: ["National Treasure: Book of Secrets"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/dc9F1vNOGbgeZrO9ejNkbgHPlfw.jpg',
        uncover_order: [4, 3, 5, 0],
    },
    '93': {
        content: '',
        submitted_by: '',
        answers: ["Twilight"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/l8O2uRzhhhuUtEpOHQZSPYIt1Uj.jpg',
        uncover_order: [0, 3, 5, 2],
    },
    '94': {
        content: '',
        submitted_by: '',
        answers: ["Blade Runner"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/63N9uy8nd9j7Eog2axPQ8lbr3Wj.jpg',
        uncover_order: [6, 7, 8, 2],
    },
    '95': {
        content: '',
        submitted_by: '',
        answers: ["Togo"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/wX0QD36a80hxulcizz7tyahYOF8.jpg',
        uncover_order: [0, 2, 3, 5],
    },
    '96': {
        content: '',
        submitted_by: '',
        answers: ["Godzilla vs. Kong"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/pgqgaUx1cJb5oZQQ5v0tNARCeBp.jpg',
        uncover_order: [0, 2, 1, 6],
    },
    '97': {
        content: '',
        submitted_by: '',
        answers: ["The Revenant"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/ji3ecJphATlVgWNY0B0RVXZizdf.jpg',
        uncover_order: [7, 5, 6, 4],
    },
    '98': {
        content: '',
        submitted_by: '',
        answers: ["Bullet Train"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/tVxDe01Zy3kZqaZRNiXFGDICdZk.jpg',
        uncover_order: [1, 0, 2, 3],
    },
    '99': {
        content: '',
        submitted_by: '',
        answers: ["A Man Called Otto"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/130H1gap9lFfiTF9iDrqNIkFvC9.jpg',
        uncover_order: [0, 2, 1, 8],
    },
    '100': {
        content: '',
        submitted_by: '',
        answers: ["Indiana Jones and the Temple of Doom"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/t7Pv44sBcxhc47kNNDDafNAgr7Y.jpg',
        uncover_order: [1, 2, 8, 4],
    },
    '101': {
        content: '',
        submitted_by: '',
        answers: ["Independence Day"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/p0BPQGSPoSa8Ml0DAf2mB2kCU0R.jpg',
        uncover_order: [0, 1, 2, 3],
    },
    '102': {
        content: '',
        submitted_by: '',
        answers: ["Taxi Driver"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/ekstpH614fwDX8DUln1a2Opz0N8.jpg',
        uncover_order: [2, 5, 0, 1],
    },
    '103': {
        content: '',
        submitted_by: '',
        answers: ["Dawn of the Dead"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/9NhVltEndWjokHbQH92KKozrzhs.jpg',
        uncover_order: [6, 8, 7, 3],
    },
    '104': {
        content: '',
        submitted_by: '',
        answers: ["RoboCop"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/hHtOgGb3NihlyRATHlKPaFApbrd.jpg',
        uncover_order: [0, 2, 5, 4],
    },
    '105': {
        content: '',
        submitted_by: '',
        answers: ["Superman"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/d7px1FQxW4tngdACVRsCSaZq0Xl.jpg',
        uncover_order: [2, 0, 8, 1],
    },
};
