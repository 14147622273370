/* eslint-disable no-useless-escape */
// @flow

export type TGTACAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    source: string,
    uncover_order: Array<string>,
    artist: string,
};

type TGTACAnswers = {
    [string]: TGTACAnswer
};

export const answers: TGTACAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Thriller"],
        affiliate_links: [],
        franchise: 'Michael Jackson',
        source: 'https://a5.mzstatic.com/us/r1000/0/Music115/v4/32/4f/fd/324ffda2-9e51-8f6a-0c2d-c6fd2b41ac55/074643811224.jpg',
        uncover_order: [5, 5, 4, 1],
        artist: 'Michael Jackson',
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["Dark Side of the Moon"],
        affiliate_links: [],
        franchise: 'Pink Floyd',
        source: 'https://a5.mzstatic.com/us/r1000/0/Music115/v4/3c/1b/a9/3c1ba9e1-15b1-03b3-3bfd-09dbd9f1705b/dj.mggvbaou.jpg',
        uncover_order: [1, 5, 3, 4],
        artist: 'Pink Floyd',
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["Rumours"],
        affiliate_links: [],
        franchise: 'Fleetwood Mac',
        source: 'https://a5.mzstatic.com/us/r1000/0/Music124/v4/4d/13/ba/4d13bac3-d3d5-7581-2c74-034219eadf2b/081227970949.jpg',
        uncover_order: [5, 6, 0, 2],
        artist: 'Fleetwood Mac',
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Whenever You Need Somebody"],
        affiliate_links: [],
        franchise: 'Rick Astley',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/ce/6d/5b/ce6d5b48-8c36-b990-3b9c-81862fadb459/0859381157694.jpg/600x600bb.jpg',
        uncover_order: [5, 3, 0, 8],
        artist: 'Rick Astley',
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Lungs"],
        affiliate_links: [],
        franchise: 'Florence + The Machine',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/46/45/58/46455821-202f-5fac-ee42-87853cb9fa03/09UMGIM14223.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 2],
        artist: 'Florence + The Machine',
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["21"],
        affiliate_links: [],
        franchise: 'Adele',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/d8/e3/f9/d8e3f9ea-d6fe-9a1b-9f13-109983d3062e/191404113868.png/600x600bb.jpg',
        uncover_order: [6, 2, 4, 8],
        artist: 'Adele',
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Midnights"],
        affiliate_links: [],
        franchise: 'Taylor Swift',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/59/13/5c/59135ccc-8425-415c-7f89-8aeada60088e/22UM1IM22440.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 8, 4, 1],
        artist: 'Taylor Swift',
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["Sigh No More"],
        affiliate_links: [],
        franchise: 'Mumford & Sons',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/f4/51/99/f45199b6-9529-c884-110d-11d21ed67d8a/602547344403_1.jpg/600x600bb.jpg',
        uncover_order: [0, 2, 4, 8],
        artist: 'Mumford & Sons',
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Demon Days"],
        affiliate_links: [],
        franchise: 'Gorillaz',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/1c/0f/81/1c0f818a-e458-dd84-6f1b-ccbdf5fe14d6/825646291045.jpg/600x600bb.jpg',
        uncover_order: [6, 0, 2, 8],
        artist: 'Gorillaz',
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["When We All Fall Asleep, Where Do We Go?"],
        affiliate_links: [],
        franchise: 'Billie Eilish',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/1a/37/d1/1a37d1b1-8508-54f2-f541-bf4e437dda76/19UMGIM05028.rgb.jpg/600x600bb.jpg',
        uncover_order: [5, 6, 4, 1],
        artist: 'Billie Eilish',
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["Jolene"],
        affiliate_links: [],
        franchise: 'Dolly Parton',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/0b/f8/1e/0bf81e88-de6e-aba3-9fd4-e7407bfbcc31/886445438048.jpg/600x600bb.jpg',
        uncover_order: [8, 5, 4, 2],
        artist: 'Dolly Parton',
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["VHS"],
        affiliate_links: [],
        franchise: 'X Ambassadors',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/ba/85/8e/ba858e00-8c2d-5c8f-c417-e577829c26fa/15UMGIM23181.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 2],
        artist: 'X Ambassadors',
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Dangerously in Love"],
        affiliate_links: [],
        franchise: 'Beyoncé',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/03/c5/d4/mzi.ldvrmhxt.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 0, 7],
        artist: 'Beyoncé',
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["Totally Krossed Out"],
        affiliate_links: [],
        franchise: 'Kriss Kross',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/ea/03/30/ea033079-3254-3a3c-e2a6-504b30095ca6/dj.wutawrld.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 2, 0],
        artist: 'Kriss Kross',
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Pure Heroine"],
        affiliate_links: [],
        franchise: 'Lorde',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/e1/d3/23/e1d323d6-a7e4-6d5e-e6f6-5105c76db133/13UAAIM68691.rgb.jpg/600x600bb.jpg',
        uncover_order: [3, 2, 5, 8],
        artist: 'Lorde',
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Ocean Eyes"],
        affiliate_links: [],
        franchise: 'Owl City',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/f6/72/94/f67294c7-f123-c698-c70b-9e9aa1b0947f/09UMGIM22612.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 3, 6, 2],
        artist: 'Owl City',
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["She's So Unusual"],
        affiliate_links: [],
        franchise: 'Cyndi Lauper',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/8a/8c/13/8a8c13e0-06af-1710-7b7d-ebec65fb8361/074643893022.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 0, 4],
        artist: 'Cyndi Lauper',
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["A Night at the Opera"],
        affiliate_links: [],
        franchise: 'Queen',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/b1/a9/84/b1a984dc-8dce-e8cb-1a0e-20293f7c500a/14DMGIM05548.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 5, 4, 8],
        artist: 'Queen',
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["Billy Talent II"],
        affiliate_links: [],
        franchise: 'Billy Talent',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/31/89/a2/mzi.ntaoftmc.jpg/600x600bb.jpg',
        uncover_order: [6, 3, 4, 2],
        artist: 'Billy Talent',
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["THE E.N.D.", "THE ENERGY NEVER DIES"],
        affiliate_links: [],
        franchise: 'The Black Eyed Peas',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/49/c5/4b/49c54bff-bb76-bad6-48a1-e1de9d12a7f8/09UMGIM13833.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 4, 2],
        artist: 'The Black Eyed Peas',
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["Dire Straits"],
        affiliate_links: [],
        franchise: 'Dire Straits',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/47/c3/21/mzi.ciyzkqao.jpg/600x600bb.jpg',
        uncover_order: [8, 2, 3, 1],
        artist: 'Dire Straits',
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["Common Courtesy"],
        affiliate_links: [],
        franchise: 'A Day To Remember',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/ca/44/2f/ca442f10-5017-f0ba-cee1-d560fefd58a9/0045778775460.png/600x600bb.jpg',
        uncover_order: [6, 2, 5, 8],
        artist: 'A Day to Remember',
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Enema of the State"],
        affiliate_links: [],
        franchise: 'Blink-182',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/c2/f2/78/c2f2783f-8e65-0b2b-80ac-b52c4cfece2d/16UMGIM35657.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 2, 4, 8],
        artist: 'Blink-182',
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["Waking Up the Neighbours"],
        affiliate_links: [],
        franchise: 'Bryan Adams',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/b0/f7/5d/b0f75dab-7e01-d4c6-61c3-e6253a2cae22/06UMGIM18645.rgb.jpg/600x600bb.jpg',
        uncover_order: [7, 6, 4, 2],
        artist: 'Bryan Adams',
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["In Absentia"],
        affiliate_links: [],
        franchise: 'Porcupine Tree',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/c7/d7/cb/c7d7cb93-6292-156d-bf68-ae324c8e6424/802644401669.jpg/600x600bb.jpg',
        uncover_order: [0, 7, 5, 8],
        artist: 'Porcupine Tree',
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["St. Elsewhere"],
        affiliate_links: [],
        franchise: 'Gnarls Barkley',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/10/77/43/mzi.mhgimyxe.jpg/600x600bb.jpg',
        uncover_order: [6, 2, 4, 1],
        artist: 'Gnarls Barkley',
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["Miss E... So Addictive"],
        affiliate_links: [],
        franchise: 'Missy Elliott',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/d1/f0/f4/d1f0f433-54c8-13e0-9736-15782c6b7bdb/mzi.fnlfdvuf.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 2],
        artist: 'Missy Elliott',
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["Nevermind"],
        affiliate_links: [],
        franchise: 'Nirvana',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/95/fd/b9/95fdb9b2-6d2b-92a6-97f2-51c1a6d77f1a/00602527874609.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 3, 1, 7],
        artist: 'Nirvana',
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["After Hours"],
        affiliate_links: [],
        franchise: 'The Weeknd',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/6f/bc/e6/6fbce6c4-c38c-72d8-4fd0-66cfff32f679/20UMGIM12176.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 1, 4],
        artist: 'The Weeknd',
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["Let's Talk About Love"],
        affiliate_links: [],
        franchise: 'Celine Dion',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/39/25/f7/3925f7d9-8190-2431-71c6-1c44cec6864b/886447917251.jpg/600x600bb.jpg',
        uncover_order: [8, 1, 5, 7],
        artist: 'Celine Dion',
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["I Never Loved a Man the Way I Love You"],
        affiliate_links: [],
        franchise: 'Aretha Franklin',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/d1/31/ee/d131eec4-614c-d661-b580-3dc32b8547e8/603497896622.jpg/600x600bb.jpg',
        uncover_order: [6, 8, 3, 2],
        artist: 'Aretha Franklin',
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Mezzanine"],
        affiliate_links: [],
        franchise: 'Massive Attack',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/0a/98/55/0a98555b-8d9d-3b46-660a-b91261557d17/00724384559953.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 2, 4],
        artist: 'Massive Attack',
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Tha Carter III"],
        affiliate_links: [],
        franchise: 'Lil Wayne',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/7d/63/39/7d6339c5-6ce7-7cf6-5f5b-6f3f3f478817/08UMGIM10308.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 4, 1, 2],
        artist: 'Lil Wayne',
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["Like a Prayer"],
        affiliate_links: [],
        franchise: 'Madonna',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/20/3c/f5/203cf53d-689e-528f-29d7-ba33758254aa/mzi.rotbotfl.jpg/600x600bb.jpg',
        uncover_order: [8, 7, 1, 5],
        artist: 'Madonna',
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["Night Visions"],
        affiliate_links: [],
        franchise: 'Imagine Dragons',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/1f/fa/09/1ffa092f-f52f-4a66-7d10-4cc5982dc747/12UMGIM46901.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 5, 4, 2],
        artist: 'Imagine Dragons',
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["AM"],
        affiliate_links: [],
        franchise: 'Arctic Monkeys',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music113/v4/cc/0f/2d/cc0f2d02-5ff1-10e7-eea2-76863a55dbad/887828031795.png/600x600bb.jpg',
        uncover_order: [6, 0, 4, 3],
        artist: 'Arctic Monkeys',
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["The Doors"],
        affiliate_links: [],
        franchise: 'The Doors',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/97/e2/ce/97e2ce16-02e1-54f6-3896-dcc55249ff7d/603497838738.jpg/600x600bb.jpg',
        uncover_order: [8, 7, 4, 2],
        artist: 'The Doors',
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["All That You Can't Leave Behind"],
        affiliate_links: [],
        franchise: 'U2',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/e3/bd/02/e3bd02e5-5f43-8047-1a2b-4a4f8914fc60/17UMGIM95495.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 2],
        artist: 'U2',
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Elephunk"],
        affiliate_links: [],
        franchise: 'The Black Eyed Peas',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/05/a3/a1/05a3a1aa-c472-a7f2-4d8b-9eacff8a1e43/22UMGIM30753.rgb.jpg/600x600bb.jpg',
        uncover_order: [3, 5, 4, 8],
        artist: 'The Black Eyed Peas',
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["Aftermath"],
        affiliate_links: [],
        franchise: 'The Rolling Stones',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/ea/26/4d/ea264dc4-5f6e-29a2-c95e-18097da49be9/00018771894520.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 8, 4, 1],
        artist: 'The Rolling Stones',
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["Born to Die"],
        affiliate_links: [],
        franchise: 'Lana Del Rey',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/5f/d9/63/5fd96387-45fa-6b94-afd8-7b2c4a24a93b/11UMGIM38959.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 8],
        artist: 'Lana Del Rey',
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["The Wall"],
        affiliate_links: [],
        franchise: 'Pink Floyd',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/3c/b4/e3/3cb4e3d0-cd77-8f18-7465-d60e6949b435/886445635850.jpg/600x600bb.jpg',
        uncover_order: [6, 2, 1, 7],
        artist: 'Pink Floyd',
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["Born In the U.S.A."],
        affiliate_links: [],
        franchise: 'Bruce Springsteen',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/32/72/68/327268ba-b9dd-b322-2a16-bdd0212df48c/074643865326.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 3, 0],
        artist: 'Bruce Springsteen',
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["In the Court of the Crimson King"],
        affiliate_links: [],
        franchise: 'King Crimson',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music5/v4/2f/c7/19/2fc71988-6871-be2c-6731-a3d0f2a6b232/Court_2500px.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 2],
        artist: 'King Crimson',
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["Graduation"],
        affiliate_links: [],
        franchise: 'Kanye West',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/2f/db/2c/2fdb2c9d-171c-c6dc-57ee-4bae2b4bb11a/07UMGIM12671.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 5, 0, 2],
        artist: 'Kanye West',
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["Kind of Blue"],
        affiliate_links: [],
        franchise: 'Miles Davis',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/7f/9f/d6/mzi.vtnaewef.jpg/600x600bb.jpg',
        uncover_order: [8, 5, 3, 2],
        artist: 'Miles Davis',
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["Put Yourself Back Together"],
        affiliate_links: [],
        franchise: 'Real Friends',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/a9/d9/e4/a9d9e4a1-a6cc-e11c-a7ee-998ab487b7c0/881034186560.png/600x600bb.jpg',
        uncover_order: [4, 2, 1, 8],
        artist: 'Real Friends',
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["Honky Ch\u00e2teau"],
        affiliate_links: [],
        franchise: 'Elton John',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/75/b6/da/75b6da76-a3e0-6a77-cbe5-ad6c42cb0692/06UMGIM48050.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 3, 5, 2],
        artist: 'Elton John',
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["Smiley Smile"],
        affiliate_links: [],
        franchise: 'The Beach Boys',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/ef/b9/c5/efb9c577-61e6-b381-ca38-b1615081d677/13UABIM03827.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 2],
        artist: 'The Beach Boys',
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["Babel"],
        affiliate_links: [],
        franchise: 'Mumford & Sons',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/80/13/0c/80130c3f-36bc-f526-8835-8dddbeec493d/602537110490.jpg/600x600bb.jpg',
        uncover_order: [2, 3, 4, 7],
        artist: 'Mumford & Sons',
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["Get Rich or Die Tryin'"],
        affiliate_links: [],
        franchise: '50 Cent',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/51/a6/c9/51a6c989-f81d-42b3-c94c-e889a7c07885/06UMGIM15592.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 2, 1],
        artist: '50 Cent',
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["Good Girl Gone Bad"],
        affiliate_links: [],
        franchise: 'Rihanna',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music123/v4/6f/ab/10/6fab1076-6c3c-7346-5003-fe902066e2d5/07UMGIM07720.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 2, 5],
        artist: 'Rihanna',
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["Takk..."],
        affiliate_links: [],
        franchise: 'Sigur Rós',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/ce/3e/b8/ce3eb805-fb13-0200-5856-081ed0014e13/190296926952.jpg/600x600bb.jpg',
        uncover_order: [0, 2, 4, 7],
        artist: 'Sigur Rós',
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["Birds In The Trap Sing McKnight"],
        affiliate_links: [],
        franchise: 'Travis Scott',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/50/f7/a3/50f7a39d-3bd5-28e9-0264-532f08b5b810/886446074726.jpg/600x600bb.jpg',
        uncover_order: [6, 0, 4, 3],
        artist: 'Travis Scott',
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["Baby One More Time"],
        affiliate_links: [],
        franchise: 'Britney Spears',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/ad/4b/ee/ad4beee5-800d-d66a-759a-b4f4dc2d4874/888880333476.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 0, 1],
        artist: 'Britney Spears',
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["Music Has the Right to Children"],
        affiliate_links: [],
        franchise: 'Boards Of Canada',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/b5/4c/c2/b54cc20d-03f5-f2c4-4a0d-9b51ad65af89/dj.txuslqgv.jpg/600x600bb.jpg',
        uncover_order: [8, 4, 1, 7],
        artist: 'Boards of Canada',
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["Songs About Jane"],
        affiliate_links: [],
        franchise: 'Maroon 5',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/d0/3e/25/d03e255d-e205-0e66-20f6-01e251896c25/14UMGIM27076.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 4, 0, 2],
        artist: 'Maroon 5',
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["Millennium"],
        affiliate_links: [],
        franchise: 'Backstreet Boys',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/5f/6b/e9/5f6be919-1b9e-30ef-45b7-cc27fc428fd5/012414167224.jpg/600x600bb.jpg',
        uncover_order: [6, 2, 1, 4],
        artist: 'Backstreet Boys',
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["Yeezus"],
        affiliate_links: [],
        franchise: 'Kanye West',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/3c/56/e7/3c56e717-06a0-b67d-e694-9b6e6e43a5a8/13UAAIM08444.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 8, 1, 7],
        artist: 'Kanye West',
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["Purple Rain"],
        affiliate_links: [],
        franchise: 'Prince',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/00/17/f2/0017f24f-e580-b77a-71a8-1bc7b75881bf/603497822065.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 1],
        artist: 'Prince',
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["All Killer, No Filler"],
        affiliate_links: [],
        franchise: 'Sum 41',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/bd/32/bb/bd32bb0b-0bb4-88bb-f32a-9ff383579d54/06UMGIM15497.rgb.jpg/600x600bb.jpg',
        uncover_order: [7, 2, 3, 5],
        artist: 'Sum 41',
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["Slippery When Wet"],
        affiliate_links: [],
        franchise: 'Bon Jovi',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/40/16/3e/40163e24-6985-b785-d4ea-cbae07d74812/06UMGIM05422.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 8, 0, 5],
        artist: 'Bon Jovi',
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["Hotel California"],
        affiliate_links: [],
        franchise: 'Eagles',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/88/16/2c/88162c3d-46db-8321-61f3-3a47404cfe76/075596050920.jpg/600x600bb.jpg',
        uncover_order: [6, 2, 0, 3],
        artist: 'Eagles',
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["Grace"],
        affiliate_links: [],
        franchise: 'Jeff Buckley',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/26/d6/e3/26d6e339-a7a9-d61e-1b5f-0852a5515a55/886445517880.jpg/600x600bb.jpg',
        uncover_order: [8, 7, 0, 2],
        artist: 'Jeff Buckley',
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["Fear of God II: Let Us Pray"],
        affiliate_links: [],
        franchise: 'Pusha T',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/5b/82/fe/5b82fef0-522e-4625-4c0b-c373d446d2d6/812814020750.jpg/600x600bb.jpg',
        uncover_order: [3, 2, 1, 7],
        artist: 'Pusha T',
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["A Rush of Blood to the Head"],
        affiliate_links: [],
        franchise: 'Coldplay',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/b9/b4/2a/b9b42ad1-1e25-5096-da43-497a247e69a3/190295978051.jpg/600x600bb.jpg',
        uncover_order: [7, 8, 0, 1],
        artist: 'Coldplay',
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["Vampire Weekend"],
        affiliate_links: [],
        franchise: 'Vampire Weekend',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/ba/01/17/ba01176d-e46f-aac5-e1be-4256c26c173e/634904831868.png/600x600bb.jpg',
        uncover_order: [8, 2, 4, 5],
        artist: 'Vampire Weekend',
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["Her Loss"],
        affiliate_links: [],
        franchise: 'Drake & 21 Savage',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/c7/00/3f/c7003f83-3a43-1201-4aec-41be71ba64c5/22UM1IM29131.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 3, 4, 1],
        artist: 'Drake & 21 Savage',
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["FutureSex/LoveSounds"],
        affiliate_links: [],
        franchise: 'Justin Timberlake',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/d5/7a/a5/d57aa570-6a18-5f37-e80f-152a5e8ff1e0/888880017444.jpg/600x600bb.jpg',
        uncover_order: [7, 8, 2, 4],
        artist: 'Justin Timberlake',
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["Talking Book"],
        affiliate_links: [],
        franchise: 'Stevie Wonder',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/62/61/61/626161c0-f4d7-e6ff-8586-768340ef278f/00602537002382.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 5, 0, 8],
        artist: 'Stevie Wonder',
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["Led Zeppelin IV"],
        affiliate_links: [],
        franchise: 'Led Zeppelin',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/5c/15/9b/5c159b27-95ca-b9a7-84e3-28e795fffd39/dj.kvkrpptq.jpg/600x600bb.jpg',
        uncover_order: [0, 8, 1, 4],
        artist: 'Led Zeppelin',
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["Oral Fixation, Vol. 2"],
        affiliate_links: [],
        franchise: 'Shakira',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Features114/v4/2a/33/9a/2a339a01-28f3-3ddd-f9e7-30c837f046cc/dj.bvmymfoa.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 0],
        artist: 'Shakira',
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["Mule Variations"],
        affiliate_links: [],
        franchise: 'Tom Waits',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/b9/c8/ea/b9c8eac9-0f83-f194-7de6-f68d21b5ed1f/0045778654734.png/600x600bb.jpg',
        uncover_order: [6, 5, 2, 1],
        artist: 'Tom Waits',
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["Boys & Girls"],
        affiliate_links: [],
        franchise: 'Alabama Shakes',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/f2/c0/c6/f2c0c63f-9956-bbc6-dc8d-c1ad94caf194/new_front_cover_digital_rh3_1500.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 4, 5],
        artist: 'Alabama Shakes',
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["Windowlicker"],
        affiliate_links: [],
        franchise: 'Aphex Twin',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music123/v4/81/20/73/812073f2-c437-fd1d-636d-6be5a4432747/0801061910532.png/600x600bb.jpg',
        uncover_order: [6, 5, 1, 2],
        artist: 'Aphex Twin',
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["Nothing But the Best"],
        affiliate_links: [],
        franchise: 'Frank Sinatra',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/0e/3c/5f/0e3c5fa9-b0f4-3643-9ad5-02b1d2d35f1f/13UAEIM06265.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 0, 4, 7],
        artist: 'Frank Sinatra',
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["Synchronicity"],
        affiliate_links: [],
        franchise: 'The Police',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/a4/67/ba/a467ba62-87df-9d10-98d2-c517f68ac870/16UMGIM60882.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 0, 2],
        artist: 'The Police',
    },
    '78': {
        content: '',
        submitted_by: '',
        answers: ["Random Access Memories"],
        affiliate_links: [],
        franchise: 'Daft Punk',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/e8/43/5f/e8435ffa-b6b9-b171-40ab-4ff3959ab661/886443919266.jpg/600x600bb.jpg',
        uncover_order: [8, 2, 4, 1],
        artist: 'Daft Punk',
    },
    '79': {
        content: '',
        submitted_by: '',
        answers: ["Wednesday Morning, 3 A.M."],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/03/ef/18/03ef1813-a39e-965c-0a1b-18df7254d1ed/074640904929.jpg/600x600bb.jpg',
        uncover_order: [6, 3, 7, 2],
        artist: 'Simon & Garfunkel',
    },
    '80': {
        content: '',
        submitted_by: '',
        answers: ["Toulouse Street"],
        affiliate_links: [],
        franchise: 'The Doobie Brothers',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/d5/ce/39/d5ce3991-f455-179b-51bb-8292ae035a1a/603497878246.jpg/600x600bb.jpg',
        uncover_order: [0, 7, 5, 1],
        artist: 'The Doobie Brothers',
    },
    '81': {
        content: '',
        submitted_by: '',
        answers: ["Spice"],
        affiliate_links: [],
        franchise: 'Spice Girls',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/c0/90/ca/c090caa0-718d-9646-15c5-bd6741ba8491/13UABIM59225.rgb.jpg/600x600bb.jpg',
        uncover_order: [4, 3, 1, 2],
        artist: 'Spice Girls',
    },
    '82': {
        content: '',
        submitted_by: '',
        answers: ["Earthwords & Music"],
        affiliate_links: [],
        franchise: 'John Hartford',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music3/v4/84/de/a2/84dea259-a78c-6868-ae8f-6d58919c7dc5/886445048629.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 0, 5],
        artist: 'John Hartford',
    },
    '83': {
        content: '',
        submitted_by: '',
        answers: ["Elephant"],
        affiliate_links: [],
        franchise: 'The White Stripes',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/07/25/09/0725098a-09f4-f240-e551-94384a590371/886448799009.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 1, 4],
        artist: 'The White Stripes',
    },
    '84': {
        content: '',
        submitted_by: '',
        answers: ["Hot Shot"],
        affiliate_links: [],
        franchise: 'Shaggy',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/ad/dc/d7/addcd70c-15b4-c1b7-fe50-f6e5eb00c06a/06UMGIM55575.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 3, 2, 1],
        artist: 'Shaggy',
    },
    '85': {
        content: '',
        submitted_by: '',
        answers: ["Sweet Baby James"],
        affiliate_links: [],
        franchise: 'James Taylor',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/ed/c3/5a/edc35aac-57d0-44aa-4969-8bb4c13aed52/603497849154.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 1, 3],
        artist: 'James Taylor',
    },
    '86': {
        content: '',
        submitted_by: '',
        answers: ["One-X"],
        affiliate_links: [],
        franchise: 'Three Days Grace',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/1e/37/05/1e370594-2363-d940-cc3e-65ca3e64d7ae/888880191748.jpg/600x600bb.jpg',
        uncover_order: [0, 8, 3, 4],
        artist: 'Three Days Grace',
    },
    '87': {
        content: '',
        submitted_by: '',
        answers: ["Highway 61 Revisited"],
        affiliate_links: [],
        franchise: 'Bob Dylan',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/f8/ff/c0/f8ffc056-55b4-2033-657d-32492d1eea25/827969239926.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 2],
        artist: 'Bob Dylan',
    },
    '88': {
        content: '',
        submitted_by: '',
        answers: ["The King of Limbs"],
        affiliate_links: [],
        franchise: 'Radiohead',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/af/b6/7b/afb67b4e-6cf8-10b3-31f1-1389e301166d/634904078768.png/600x600bb.jpg',
        uncover_order: [8, 0, 2, 4],
        artist: 'Radiohead',
    },
    '89': {
        content: '',
        submitted_by: '',
        answers: ["Moody Blue"],
        affiliate_links: [],
        franchise: 'Elvis Presley',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/2e/38/cd/2e38cd65-97e0-b45a-0111-83bcfe5adbf4/078635242829.jpg/600x600bb.jpg',
        uncover_order: [8, 4, 7, 2],
        artist: 'Elvis Presley',
    },
    '90': {
        content: '',
        submitted_by: '',
        answers: ["London Calling"],
        affiliate_links: [],
        franchise: 'The Clash',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/45/d7/17/45d71740-b204-de23-3f9e-f2f823296f1d/886443520721.jpg/600x600bb.jpg',
        uncover_order: [2, 5, 4, 0],
        artist: 'The Clash',
    },
    '91': {
        content: '',
        submitted_by: '',
        answers: ["Rhythm of the Rain"],
        affiliate_links: [],
        franchise: 'The Cascades',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/45/08/32/4508325a-6e59-3a4e-8074-e5cf6c889bca/s05.svcwqodm.jpg/600x600bb.jpg',
        uncover_order: [6, 7, 1, 5],
        artist: 'The Cascades',
    },
    '92': {
        content: '',
        submitted_by: '',
        answers: ["Madman Across the Water"],
        affiliate_links: [],
        franchise: 'Elton John',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/46/d7/21/46d72100-a10b-902e-8ee6-98a22ad26769/06UMGIM52391.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 0],
        artist: 'Elton John',
    },
    '93': {
        content: '',
        submitted_by: '',
        answers: ["Hands All Over"],
        affiliate_links: [],
        franchise: 'Maroon 5',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/5f/6e/4c/5f6e4c8b-eba6-f722-caf4-c5773dea7fa1/14UMGIM27067.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 1, 3],
        artist: 'Maroon 5',
    },
    '94': {
        content: '',
        submitted_by: '',
        answers: ["Rodeo"],
        affiliate_links: [],
        franchise: 'Travis Scott',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/71/87/78/7187786f-70af-fd36-fc7f-a4ba61b65d98/886445454987.jpg/600x600bb.jpg',
        uncover_order: [0, 7, 1, 4],
        artist: 'Travis Scott',
    },
    '95': {
        content: '',
        submitted_by: '',
        answers: ["Bookends"],
        affiliate_links: [],
        franchise: 'Simon & Garfunkel',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music113/v4/1c/3c/09/1c3c0934-8ae5-fc87-2647-e104057d41f4/074640952920.jpg/600x600bb.jpg',
        uncover_order: [0, 5, 4, 7],
        artist: 'Simon & Garfunkel',
    },
    '96': {
        content: '',
        submitted_by: '',
        answers: ["(Whats the Story) Morning Glory?", "Morning Glory?"],
        affiliate_links: [],
        franchise: 'Oasis',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music113/v4/04/92/e0/0492e08b-cbcc-9969-9ad6-8f5a0888068c/5051961007107.jpg/600x600bb.jpg',
        uncover_order: [4, 7, 3, 1],
        artist: 'Oasis',
    },
    '97': {
        content: '',
        submitted_by: '',
        answers: ["Joyride"],
        affiliate_links: [],
        franchise: 'Roxette',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/72/5f/57/725f5745-ec92-a7fa-5801-3ecb73867c64/5099960655752.jpg/600x600bb.jpg',
        uncover_order: [2, 0, 6, 8],
        artist: 'Roxette',
    },
    '98': {
        content: '',
        submitted_by: '',
        answers: ["2001"],
        affiliate_links: [],
        franchise: 'Dr. Dre',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/fd/63/ed/fd63ed6c-178c-7fa9-4844-29a7326bf655/06UMGIM01277.rgb.jpg/600x600bb.jpg',
        uncover_order: [4, 6, 1, 7],
        artist: 'Dr. Dre',
    },
    '99': {
        content: '',
        submitted_by: '',
        answers: ["Meet the Woo"],
        affiliate_links: [],
        franchise: 'Pop Smoke',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/01/a8/c5/01a8c5e2-0921-cfca-7b8f-7b266c6925ee/19UMGIM67514.rgb.jpg/600x600bb.jpg',
        uncover_order: [4, 5, 0, 3],
        artist: 'Pop Smoke',
    },
    '100': {
        content: '',
        submitted_by: '',
        answers: ["Metamorphosis"],
        affiliate_links: [],
        franchise: 'Hilary Duff',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/29/64/6c/29646c20-89c0-e065-681b-527d562b40fa/09BVMIM00850.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 5, 3, 1],
        artist: 'Hilary Duff',
    },
    '101': {
        content: '',
        submitted_by: '',
        answers: ["Chemtrails Over the Country Club"],
        affiliate_links: [],
        franchise: 'Lana Del Rey',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/74/79/c5/7479c518-a8ba-2054-b978-c87293194260/20UM1IM09851.rgb.jpg/600x600bb.jpg',
        uncover_order: [4, 3, 5, 8],
        artist: 'Lana Del Rey',
    },
    '102': {
        content: '',
        submitted_by: '',
        answers: ["Animal"],
        affiliate_links: [],
        franchise: 'Kesha',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/4f/af/ce/4fafcec5-956c-0539-67d3-f6a2493137fb/884977451856.jpg/600x600bb.jpg',
        uncover_order: [2, 6, 4, 7],
        artist: 'Ke$ha',
    },
    '103': {
        content: '',
        submitted_by: '',
        answers: ["Cuz I Love You"],
        affiliate_links: [],
        franchise: 'Lizzo',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/d5/4d/a3/d54da3ff-d6bf-9685-50e2-29117c2b6c53/075679853585.jpg/600x600bb.jpg',
        uncover_order: [6, 7, 8, 4],
        artist: 'Lizzo',
    },
    '104': {
        content: '',
        submitted_by: '',
        answers: ["The Trinity"],
        affiliate_links: [],
        franchise: 'Sean Paul',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/6b/a9/89/6ba9895c-5aff-8be1-4ff9-9f88369077b1/dj.decwpjor.jpg/600x600bb.jpg',
        uncover_order: [7, 1, 6, 8],
        artist: 'Sean Paul',
    },
    '105': {
        content: '',
        submitted_by: '',
        answers: ["Imagine"],
        affiliate_links: [],
        franchise: 'John Lennon',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/21/e3/b0/21e3b048-c917-92c4-bd7d-ace44797b388/13UABIM52808.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 6, 4, 1],
        artist: 'John Lennon',
    },
    '106': {
        content: '',
        submitted_by: '',
        answers: ["I AM...SASHA FIERCE", "I Am Sasha Fierce"],
        affiliate_links: [],
        franchise: 'Beyoncé',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/35/0f/55/350f55da-2104-162a-5872-cb35fef30410/mzi.morbeoaw.jpg/600x600bb.jpg',
        uncover_order: [7, 2, 0, 4],
        artist: 'Beyoncé',
    },
    '107': {
        content: '',
        submitted_by: '',
        answers: ["Insight Out"],
        affiliate_links: [],
        franchise: 'The Association',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/4d/d3/da/4dd3dac2-f525-4f78-d0b1-5cde2dee5840/s06.cvhslxqj.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 2],
        artist: 'The Association',
    },
    '108': {
        content: '',
        submitted_by: '',
        answers: ["So Much Fun"],
        affiliate_links: [],
        franchise: 'Young Thug',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/1f/f3/57/1ff35761-4124-912a-baef-02b236c977df/075679838612.jpg/600x600bb.jpg',
        uncover_order: [0, 8, 6, 2],
        artist: 'Young Thug',
    },
    '109': {
        content: '',
        submitted_by: '',
        answers: ["G I R L", "GIRL"],
        affiliate_links: [],
        franchise: 'Pharrell Williams',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/f4/43/16/f4431607-15c4-883c-3fbe-dd6abbbe03e7/886444516877.jpg/600x600bb.jpg',
        uncover_order: [0, 3, 4, 8],
        artist: 'Pharrell Williams',
    },
    '110': {
        content: '',
        submitted_by: '',
        answers: ["Rated R"],
        affiliate_links: [],
        franchise: 'Rihanna',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music113/v4/7c/bc/a4/7cbca45e-be5d-5243-acbc-b0bcb2369658/16UMGIM58840.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 3, 4, 7],
        artist: 'Rihanna',
    },
    '111': {
        content: '',
        submitted_by: '',
        answers: ["Naturally"],
        affiliate_links: [],
        franchise: 'Three Dog Night',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/44/d5/28/44d528cf-bb8f-263b-e76e-537a40838e4b/00076743135521.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 2],
        artist: 'Three Dog Night',
    },
    '112': {
        content: '',
        submitted_by: '',
        answers: ["Wheatus"],
        affiliate_links: [],
        franchise: 'Wheatus',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/f7/c3/33/f7c333e4-b2b5-b49d-1406-f51bdac6a8aa/mzi.bllmhdje.jpg/600x600bb.jpg',
        uncover_order: [6, 7, 2, 1],
        artist: 'Wheatus',
    },
    '113': {
        content: '',
        submitted_by: '',
        answers: ["El Dorado"],
        affiliate_links: [],
        franchise: '24kgoldn',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/af/1d/2d/af1d2d4c-66bb-bfb4-d0cd-b5e90036143e/886449094776.jpg/600x600bb.jpg',
        uncover_order: [6, 8, 4, 2],
        artist: '24kGoldn',
    },
    '114': {
        content: '',
        submitted_by: '',
        answers: ["Blur"],
        affiliate_links: [],
        franchise: 'Blur',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/ed/73/cc/ed73cc61-8244-4f45-90fc-23ad8a3ee7e4/5099997227359.jpg/600x600bb.jpg',
        uncover_order: [2, 0, 5, 4],
        artist: 'Blur',
    },
    '115': {
        content: '',
        submitted_by: '',
        answers: ["Please Hammer Don't Hurt 'Em"],
        affiliate_links: [],
        franchise: 'Mc Hammer',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/88/3d/74/883d74ea-aa53-99d8-e00c-f38fbba93d1d/13UABIM56742.rgb.jpg/600x600bb.jpg',
        uncover_order: [3, 8, 4, 2],
        artist: 'MC Hammer',
    },
    '116': {
        content: '',
        submitted_by: '',
        answers: ["Discovery"],
        affiliate_links: [],
        franchise: 'Daft Punk',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/9e/f3/19/9ef31946-26de-0a95-c771-30457c5c01ec/dj.huxlnxyy.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 0, 5],
        artist: 'Daft Punk',
    },
    '117': {
        content: '',
        submitted_by: '',
        answers: ["What A Wonderful World"],
        affiliate_links: [],
        franchise: 'Louis Armstrong',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/14/14/ef/1414ef36-705a-4483-d7fc-ca636f2cb137/12UMGIM11012.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 3, 0, 5],
        artist: 'Louis Armstrong',
    },
    '118': {
        content: '',
        submitted_by: '',
        answers: ["Unorthodox Jukebox"],
        affiliate_links: [],
        franchise: 'Bruno Mars',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/e0/a4/7c/e0a47c6f-005a-9f9f-ce29-8e858e2bcfcb/075679957283.jpg/600x600bb.jpg',
        uncover_order: [5, 3, 4, 2],
        artist: 'Bruno Mars',
    },
    '119': {
        content: '',
        submitted_by: '',
        answers: ["I Walk the Line"],
        affiliate_links: [],
        franchise: 'Johnny Cash',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/e3/6d/c2/e36dc2de-ded8-426e-91fe-80632242a72c/886444469319.jpg/600x600bb.jpg',
        uncover_order: [7, 0, 1, 5],
        artist: 'Johnny Cash',
    },
    '120': {
        content: '',
        submitted_by: '',
        answers: ["F*CK LOVE 3+: OVER YOU"],
        affiliate_links: [],
        franchise: 'The Kid Laroi',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/9d/69/f1/9d69f15c-7bcc-24e9-0adc-ec3afd0bf5cc/886449473663.jpg/600x600bb.jpg',
        uncover_order: [7, 4, 5, 0],
        artist: 'The Kid LAROI',
    },
    '121': {
        content: '',
        submitted_by: '',
        answers: ["Dreamland"],
        affiliate_links: [],
        franchise: 'Glass Animals',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/da/8b/77/da8b7731-6f4f-eacf-5e74-8b23389eefa1/20UMGIM03371.rgb.jpg/600x600bb.jpg',
        uncover_order: [3, 5, 4, 8],
        artist: 'Glass Animals',
    },
    '122': {
        content: '',
        submitted_by: '',
        answers: ["Meteora"],
        affiliate_links: [],
        franchise: 'Linkin Park',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/dd/7d/72/dd7d7259-d27f-5b3e-ce64-9e304d2cb40f/dj.rxzrauer.jpg/600x600bb.jpg',
        uncover_order: [6, 0, 2, 5],
        artist: 'LINKIN PARK',
    },
    '123': {
        content: '',
        submitted_by: '',
        answers: ["Off the Wall"],
        affiliate_links: [],
        franchise: 'Michael Jackson',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/cc/b0/7f/ccb07f0d-1530-00b0-244a-6332daffc2b9/dj.xnuhftrz.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 1, 3],
        artist: 'Michael Jackson',
    },
    '124': {
        content: '',
        submitted_by: '',
        answers: ["Arrival"],
        affiliate_links: [],
        franchise: 'Abba',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/69/4d/b4/694db440-1fdd-0112-16a0-ae501501cb32/14UMGIM07610.rgb.jpg/600x600bb.jpg',
        uncover_order: [3, 5, 4, 2],
        artist: 'ABBA',
    },
    '125': {
        content: '',
        submitted_by: '',
        answers: ["Future Nostalgia"],
        affiliate_links: [],
        franchise: 'Dua Lipa',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/6c/11/d6/6c11d681-aa3a-d59e-4c2e-f77e181026ab/190295092665.jpg/600x600bb.jpg',
        uncover_order: [2, 0, 7, 3],
        artist: 'Dua Lipa',
    },
    '126': {
        content: '',
        submitted_by: '',
        answers: ["Never Too Much"],
        affiliate_links: [],
        franchise: 'Luther Vandross',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/81/41/87/81418780-b371-faa6-a3b5-5073be62a6ae/mzi.mskephqn.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 1, 0],
        artist: 'Luther Vandross',
    },
    '127': {
        content: '',
        submitted_by: '',
        answers: ["Harry's House"],
        affiliate_links: [],
        franchise: 'Harry Styles',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/2a/19/fb/2a19fb85-2f70-9e44-f2a9-82abe679b88e/886449990061.jpg/600x600bb.jpg',
        uncover_order: [6, 4, 1, 5],
        artist: 'Harry Styles',
    },
    '128': {
        content: '',
        submitted_by: '',
        answers: ["Californication"],
        affiliate_links: [],
        franchise: 'Red Hot Chili Peppers',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/04/8e/ff/048effa0-da05-a993-f2e0-172cd0613377/093624931942.jpg/600x600bb.jpg',
        uncover_order: [6, 7, 4, 1],
        artist: 'Red Hot Chili Peppers',
    },
    '129': {
        content: '',
        submitted_by: '',
        answers: ["Dusty in Memphis"],
        affiliate_links: [],
        franchise: 'Dusty Springfield',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/06/e8/3d/mzi.ckgjvgxx.jpg/600x600bb.jpg',
        uncover_order: [4, 7, 2, 1],
        artist: 'Dusty Springfield',
    },
    '130': {
        content: '',
        submitted_by: '',
        answers: ["Not 4 Sale"],
        affiliate_links: [],
        franchise: 'Kardinal Offishall',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/32/05/f8/3205f888-e40d-86d1-6fb1-72e9e732d7c6/08UMGIM19720.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 7, 4, 2],
        artist: 'Kardinal Offishall',
    },
    '131': {
        content: '',
        submitted_by: '',
        answers: ["GUTS"],
        affiliate_links: [],
        franchise: 'Olivia Rodrigo',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/9b/d8/9c/9bd89c9e-b44d-ad25-1516-b9b30f64fd2a/23UMGIM71510.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 1, 8, 5],
        artist: 'Olivia Rodrigo',
    },
    '132': {
        content: '',
        submitted_by: '',
        answers: ["The Heist"],
        affiliate_links: [],
        franchise: 'Macklemore&ryan Lewis',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/44/40/83/44408300-636f-8cae-d508-9dd96db48cea/707541528191.jpg/600x600bb.jpg',
        uncover_order: [0, 1, 2, 6],
        artist: 'Macklemore & Ryan Lewis',
    },
    '133': {
        content: '',
        submitted_by: '',
        answers: ["The Fame"],
        affiliate_links: [],
        franchise: 'Lady Gaga',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/a6/68/28/a66828c0-3fe3-5419-374d-ad98739f3166/08UMGIM13954.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 2, 4, 7],
        artist: 'Lady Gaga',
    },
    '134': {
        content: '',
        submitted_by: '',
        answers: ["18 Months"],
        affiliate_links: [],
        franchise: 'Calvin Harris',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/ab/af/22/abaf2296-ed4d-6da2-c24d-503cfdfa4c1f/0617465356858.jpg/600x600bb.jpg',
        uncover_order: [6, 1, 8, 5],
        artist: 'Calvin Harris',
    },
    '135': {
        content: '',
        submitted_by: '',
        answers: ["Believe"],
        affiliate_links: [],
        franchise: 'Cher',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/bb/90/f3/bb90f392-b78d-f180-ba6b-f04d2d1e62fd/s05.kfnyrece.jpg/600x600bb.jpg',
        uncover_order: [6, 8, 2, 1],
        artist: 'Cher',
    },
    '136': {
        content: '',
        submitted_by: '',
        answers: ["Let Go"],
        affiliate_links: [],
        franchise: 'Avril Lavigne',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/32/2c/f3/322cf324-9ea1-3962-865a-f4f9bf83764d/888880191069.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 3, 1],
        artist: 'Avril Lavigne',
    },
    '137': {
        content: '',
        submitted_by: '',
        answers: ["Heaven or Las Vegas"],
        affiliate_links: [],
        franchise: 'Cocteau Twins',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/76/cd/61/76cd61e7-0714-dce5-c48e-0f05f8fcb84b/652637001280.png/600x600bb.jpg',
        uncover_order: [5, 7, 8, 2],
        artist: 'Cocteau Twins',
    },
    '138': {
        content: '',
        submitted_by: '',
        answers: ["Wandering Spirit"],
        affiliate_links: [],
        franchise: 'Mick Jagger',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music123/v4/e8/e2/e4/e8e2e4b6-7012-909b-3c55-539acb137ff8/19UMGIM50889.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 0, 2, 5],
        artist: 'Mick Jagger',
    },
    '139': {
        content: '',
        submitted_by: '',
        answers: ["Love Tracks"],
        affiliate_links: [],
        franchise: 'Gloria Gaynor',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music123/v4/0c/72/a1/0c72a174-9208-6c86-e479-79865376e904/19UMGIM66006.rgb.jpg/600x600bb.jpg',
        uncover_order: [3, 5, 8, 6],
        artist: 'Gloria Gaynor',
    },
    '140': {
        content: '',
        submitted_by: '',
        answers: ["Celebration"],
        affiliate_links: [],
        franchise: 'Madonna',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/7a/a8/cb/7aa8cb1c-c168-e03f-07bb-4c7bfd6f11b4/mzi.wjlsmeif.jpg/600x600bb.jpg',
        uncover_order: [8, 5, 6, 4],
        artist: 'Madonna',
    },
    '141': {
        content: '',
        submitted_by: '',
        answers: ["Who's Next"],
        affiliate_links: [],
        franchise: 'The Who',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/1c/e8/a4/1ce8a442-eef1-0af2-cf09-30ef0e734731/00602547269683.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 8, 7, 5],
        artist: 'The Who',
    },
    '142': {
        content: '',
        submitted_by: '',
        answers: ["Pitbull Starring In: Rebelution"],
        affiliate_links: [],
        franchise: 'Pitbull',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/4e/c3/09/4ec3096e-4597-6469-f576-26658fff296d/mzi.botoocbc.jpg/600x600bb.jpg',
        uncover_order: [7, 3, 0, 1],
        artist: 'Pitbull',
    },
    '143': {
        content: '',
        submitted_by: '',
        answers: ["Songs in A Minor"],
        affiliate_links: [],
        franchise: 'Alicia Keys',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/bb/4d/55/bb4d552c-04d8-29ba-1b41-6ff8bcd199a5/808132000222.jpg/600x600bb.jpg',
        uncover_order: [5, 6, 2, 3],
        artist: 'Alicia Keys',
    },
    '144': {
        content: '',
        submitted_by: '',
        answers: ["\u00f7", "Divide"],
        affiliate_links: [],
        franchise: 'Ed Sheeran',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music111/v4/2d/1c/4f/2d1c4fd7-018c-0529-693b-c67fea53b698/190295851286.jpg/600x600bb.jpg',
        uncover_order: [2, 6, 5, 1],
        artist: 'Ed Sheeran',
    },
    '145': {
        content: '',
        submitted_by: '',
        answers: ["Torches"],
        affiliate_links: [],
        franchise: 'Foster the People',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/ba/07/5b/ba075b3c-f0c4-b519-59f3-7ae74d43246b/dj.lajxsvkg.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 5, 3],
        artist: 'Foster the People',
    },
    '146': {
        content: '',
        submitted_by: '',
        answers: ["What's Love Got to Do with It"],
        affiliate_links: [],
        franchise: 'Tina Turner',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/99/25/14/99251432-af5d-fea9-fff6-dd21cd63378f/077778818953.jpg/600x600bb.jpg',
        uncover_order: [4, 3, 8, 6],
        artist: 'Tina Turner',
    },
    '147': {
        content: '',
        submitted_by: '',
        answers: ["Title"],
        affiliate_links: [],
        franchise: 'Meghan Trainor',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music7/v4/1b/12/6a/1b126a39-d244-8799-787e-ea3e350ea24d/dj.auhlmenx.jpg/600x600bb.jpg',
        uncover_order: [5, 6, 8, 3],
        artist: 'Meghan Trainor',
    },
    '148': {
        content: '',
        submitted_by: '',
        answers: ["thank u, next"],
        affiliate_links: [],
        franchise: 'Ariana Grande',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/e4/06/d3/e406d3d8-2507-e585-c919-60bc5446985c/19UMGIM03689.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 8, 5, 2],
        artist: 'Ariana Grande',
    },
    '149': {
        content: '',
        submitted_by: '',
        answers: ["beerbongs & bentleys"],
        affiliate_links: [],
        franchise: 'Post Malone',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/600x600bb.jpg',
        uncover_order: [5, 7, 8, 0],
        artist: 'Post Malone',
    },
    '150': {
        content: '',
        submitted_by: '',
        answers: ["This Is Acting"],
        affiliate_links: [],
        franchise: 'Sia',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/bc/13/27/bc13275c-8b26-802d-771b-d15ae00fb530/mzm.hvpwjsvi.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 5, 4],
        artist: 'Sia',
    },
    '151': {
        content: '',
        submitted_by: '',
        answers: ["Love in the Future"],
        affiliate_links: [],
        franchise: 'John Legend',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music128/v4/c2/ce/1b/c2ce1be5-de8d-eabd-eb14-ea126e166620/886443503847.jpg/600x600bb.jpg',
        uncover_order: [0, 3, 6, 1],
        artist: 'John Legend',
    },
    '152': {
        content: '',
        submitted_by: '',
        answers: ["True"],
        affiliate_links: [],
        franchise: 'Avicii',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/63/e5/39/63e53993-0eff-18e2-d756-ce3d56d98270/00602537491377.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 2, 1, 8],
        artist: 'Avicii',
    },
    '153': {
        content: '',
        submitted_by: '',
        answers: ["1989"],
        affiliate_links: [],
        franchise: 'Taylor Swift',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/09/01/16/090116af-770e-23da-21a9-6bd30782eda5/00843930013562.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 3, 5, 4],
        artist: 'Taylor Swift',
    },
    '154': {
        content: '',
        submitted_by: '',
        answers: ["Baby It's You"],
        affiliate_links: [],
        franchise: 'The Shirelles',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/ca/11/cd/mzi.uqgwignu.jpg/600x600bb.jpg',
        uncover_order: [7, 3, 4, 6],
        artist: 'The Shirelles',
    },
    '155': {
        content: '',
        submitted_by: '',
        answers: ["Native"],
        affiliate_links: [],
        franchise: 'Onerepublic',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/93/c1/37/93c137c8-90d8-687a-4f8a-f117ca99c0a1/14UMGIM12027.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 6, 0, 7],
        artist: 'OneRepublic',
    },
    '156': {
        content: '',
        submitted_by: '',
        answers: ["Fetty Wap"],
        affiliate_links: [],
        franchise: 'Fetty Wap',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/07/cf/29/07cf29a9-934e-0769-3eef-548f86aa28f6/814908020202.jpg/600x600bb.jpg',
        uncover_order: [2, 7, 6, 5],
        artist: 'Fetty Wap',
    },
    '157': {
        content: '',
        submitted_by: '',
        answers: ["And Then... Along Comes The Association"],
        affiliate_links: [],
        franchise: 'The Association',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/3e/20/fe/3e20fea9-7c27-ae6c-9907-239b30c1c560/603497867608.jpg/600x600bb.jpg',
        uncover_order: [3, 4, 5, 6],
        artist: 'The Association',
    },
    '158': {
        content: '',
        submitted_by: '',
        answers: ["When the World Comes Down"],
        affiliate_links: [],
        franchise: 'The All-american Rejects',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music128/v4/f8/d4/d8/f8d4d8d7-ab65-a89d-757d-b392e2ba9d35/00602517932661.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 7, 8, 0],
        artist: 'The All-American Rejects',
    },
    '159': {
        content: '',
        submitted_by: '',
        answers: ["Pink Friday: Roman Reloaded"],
        affiliate_links: [],
        franchise: 'Nicki Minaj',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/a1/86/57/a1865738-1038-6c42-0679-fa03d8d1d838/12UMGIM12513.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 1, 3, 0],
        artist: 'Nicki Minaj',
    },
    '160': {
        content: '',
        submitted_by: '',
        answers: ["Keep On Pushing"],
        affiliate_links: [],
        franchise: 'The Impressions',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/1d/1b/f7/1d1bf72a-f081-d768-8390-a3731302a32c/00602557467956.rgb.jpg/600x600bb.jpg',
        uncover_order: [7, 6, 5, 3],
        artist: 'The Impressions',
    },
    '161': {
        content: '',
        submitted_by: '',
        answers: ["Changes In Latitudes, Changes In Attitudes"],
        affiliate_links: [],
        franchise: 'Jimmy Buffett',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/e9/62/57/e9625701-913c-59a4-6c5e-76c34b3bf103/24UMGIM63090.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 8, 5, 3],
        artist: 'Jimmy Buffett',
    },
    '162': {
        content: '',
        submitted_by: '',
        answers: ["A Gift & a Curse"],
        affiliate_links: [],
        franchise: 'Gunna',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/82/29/76/822976d1-f1db-6626-e2f7-55343995ecd9/075679678485.jpg/600x600bb.jpg',
        uncover_order: [0, 5, 1, 7],
        artist: 'Gunna',
    },
    '163': {
        content: '',
        submitted_by: '',
        answers: ["The College Dropout"],
        affiliate_links: [],
        franchise: 'Kanye West',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/0d/73/b1/0d73b181-2716-f7a6-a340-0a3f81eacfb9/06UMGIM15686.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 0, 4, 2],
        artist: 'Kanye West',
    },
    '164': {
        content: '',
        submitted_by: '',
        answers: ["My House"],
        affiliate_links: [],
        franchise: 'Flo Rida',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/a9/aa/d0/a9aad0bb-e8a3-768f-8555-4b644a17553e/075679930903.jpg/600x600bb.jpg',
        uncover_order: [0, 2, 8, 1],
        artist: 'Flo Rida',
    },
    '165': {
        content: '',
        submitted_by: '',
        answers: ["Blowin' Your Mind!"],
        affiliate_links: [],
        franchise: 'Van Morrison',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music1/v4/dd/1d/9d/dd1d9dd0-b3ba-67e9-0afe-8369089f222a/886445460452.jpg/600x600bb.jpg',
        uncover_order: [7, 8, 3, 6],
        artist: 'Van Morrison',
    },
    '166': {
        content: '',
        submitted_by: '',
        answers: ["Bend Me, Shape Me"],
        affiliate_links: [],
        franchise: 'The American Breed',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music123/v4/2f/b5/3a/2fb53a28-2048-7658-7d1c-45cdb5dca108/19UM1IM11767.rgb.jpg/600x600bb.jpg',
        uncover_order: [4, 5, 3, 7],
        artist: 'The American Breed',
    },
    '167': {
        content: '',
        submitted_by: '',
        answers: ["The Decca Singles"],
        affiliate_links: [],
        franchise: 'Bobby Darin',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/80/47/65/8047650c-cff7-d8f4-ecf3-5d5fb4958eea/23UM1IM33456.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 3, 5, 6],
        artist: 'Bobby Darin',
    },
    '168': {
        content: '',
        submitted_by: '',
        answers: ["A Study of the Human Experience Volume One"],
        affiliate_links: [],
        franchise: 'GAYLE',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/07/c3/aa/07c3aa6f-33fd-92c3-dd03-1e8ff6ba9bbc/075679756992.jpg/600x600bb.jpg',
        uncover_order: [6, 8, 1, 2],
        artist: 'GAYLE',
    },
    '169': {
        content: '',
        submitted_by: '',
        answers: ["You Are My Sunshine"],
        affiliate_links: [],
        franchise: 'Jimmie Davis',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/28/d2/08/mzi.dibyebmf.tif/600x600bb.jpg',
        uncover_order: [6, 7, 8, 3],
        artist: 'Jimmie Davis',
    },
    '170': {
        content: '',
        submitted_by: '',
        answers: ["Pray for the Wicked"],
        affiliate_links: [],
        franchise: 'Panic! At The Disco',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/3b/92/c7/3b92c7e4-92eb-dd0f-68d5-b8b5bd357ec6/075679875136.jpg/600x600bb.jpg',
        uncover_order: [2, 5, 7, 8],
        artist: 'Panic! At the Disco',
    },
    '171': {
        content: '',
        submitted_by: '',
        answers: ["Wolfgang Amadeus Phoenix"],
        affiliate_links: [],
        franchise: 'Phoenix',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/28/8d/ab/288dabdd-f8de-80f6-1aab-40a24cb1b33f/00892038002190_Cover.jpg/600x600bb.jpg',
        uncover_order: [7, 6, 8, 5],
        artist: 'Phoenix',
    },
    '172': {
        content: '',
        submitted_by: '',
        answers: ["Loyalty Over Royalty"],
        affiliate_links: [],
        franchise: 'CJ',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/19/2b/01/192b01c3-8247-4b06-0085-1f7350902cd3/093624882756.jpg/600x600bb.jpg',
        uncover_order: [6, 3, 4, 0],
        artist: 'CJ',
    },
    '173': {
        content: '',
        submitted_by: '',
        answers: ["Fever"],
        affiliate_links: [],
        franchise: 'Kylie Minogue',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/3a/1a/38/3a1a38cc-48d1-efb2-9657-31f6844f44f7/724353767051.jpg/600x600bb.jpg',
        uncover_order: [2, 3, 5, 6],
        artist: 'Kylie Minogue',
    },
    '174': {
        content: '',
        submitted_by: '',
        answers: ["These Days"],
        affiliate_links: [],
        franchise: 'Bon Jovi',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music123/v4/42/c7/95/42c7950c-2d27-8f50-97fe-63ae66cbe3fe/14UMGIM44518.rgb.jpg/600x600bb.jpg',
        uncover_order: [7, 6, 5, 3],
        artist: 'Bon Jovi',
    },
    '175': {
        content: '',
        submitted_by: '',
        answers: ["Manifesto"],
        affiliate_links: [],
        franchise: 'Roxy Music',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/07/83/17/07831789-4b2c-5913-05cc-e2599bd2dd3b/13ULAIM48844.rgb.jpg/600x600bb.jpg',
        uncover_order: [4, 7, 8, 5],
        artist: 'Roxy Music',
    },
    '176': {
        content: '',
        submitted_by: '',
        answers: ["Motion"],
        affiliate_links: [],
        franchise: 'Calvin Harris',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/da/50/cc/da50cc80-3515-a38d-369b-0d700ffd249d/886444820448.jpg/600x600bb.jpg',
        uncover_order: [8, 2, 0, 6],
        artist: 'Calvin Harris',
    },
};
